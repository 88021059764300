
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Formik, Form, useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Box, Button, Select, MenuItem, InputLabel, Typography, FormControl, FormControlLabel, Switch, Chip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';

import { useLanguage } from '../contexts/LanguageContext';
import { useMessageService } from '../services/MessageService';
import { buttonStyle, toolbarConfig } from '../components/styledComponents'
import { QuestionType, AssessmentType, LearningType } from '../utils/enums';
import { processMessageToChatGPT, addQuestionsSchema, handleAutomaticEncodingChange, handleSubmit, 
         initialQuestionValues, getCompetencies, getActivities, updateCompetenciesForQuestion } from '../utils/QuestionUtils';
import { traineeCompetenceAreas, studentCompetenceAreas } from "../assets/frameworksData";

import QuestionTypeSelector from './QuestionTypeSelector';
import LearningTypeSelector from './LearningTypeSelector';
import TextFieldWrapper from './TextFieldWrapper';
import FrameworkSelector from './FrameworkSelector';
import CompetenciesSelector from './CompetenciesSelector';
import ChooseNumberQuestions from './ChooseNumberQuestions';
import AdoptionTypeSelector from './AdoptionTypeSelector';
import ChooseOptions from './ChooseOptions';

const AddLearningQuestions = ({ setQuestions, questions, assessmentType, workshops, splitWorkshops }) => {

  const [numberOfQuestions, setNumberOfQuestions] = useState(1);
  const [response, setResponse] = useState("");
  const [error, setError] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); 
  const [helpWithAI, setHelpWithAI] = useState(false);
  const [automaticEncoding, setAutomaticEncoding] = useState(false);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const { languageCode } = useLanguage();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { getMessage } = useMessageService();


  const FormResetWatcher = ({ splitWorkshops, initialQuestionValues }) => {

    const { values, setFieldValue } = useFormikContext();
    useEffect(() => {
      if (!splitWorkshops) {
        // Reset only the category field
        setFieldValue('workshop', initialQuestionValues.workshop);
      }
    }, [splitWorkshops, setFieldValue, initialQuestionValues]);

    return null;
  };

  // Event handler for changing the state of helpWithAI
  const handleHelpWithAIChange = (event) => {
    setHelpWithAI(event.target.checked);
    setAutomaticEncoding(event.target.checked);
    setSelectedCompetencies([]);
  };
 
  const handleSend = async (message, type) => {
      setResponse("");
      setIsLoading(false);
      setIsLoaded(false);
      setError(null); // Reset error message

      setIsLoading(true);

      try {
        const response = await processMessageToChatGPT(message, type);
        console.log("response: ", response)
        setResponse(response);
        setIsLoaded(true);
        setIsLoading(false);
        return response;
      } catch (error) {
        setError(error.message || "An unexpected error occurred");
        setIsLoading(false);      
        }
    };

  const handleCompetencyChange = (event) => {
    setSelectedCompetencies([...selectedCompetencies, event.target.value]);
  };

  const handleDeleteCompetency = (competencyToRemove) => {
    setSelectedCompetencies(selectedCompetencies.filter(competency => competency !== competencyToRemove));
  };

  // Define the onSubmit function
  const onSubmit = (values, formikBag) => {
    handleSubmit(values, formikBag, {
      handleSend,
      languageCode,
      numberOfQuestions: values.numberOfQuestions,
      helpWithAI,
      questions,
      setQuestions,
      selectedCompetencies,
      setSelectedCompetencies,
      setAutomaticEncoding,
      editorState
    });
  };

  return (
    <>
    <Box flexDirection="column" display="flex" sx={{backgroundColor: "#fff", marginBottom: "20px", paddingLeft: "20px",}} >
      <FormControlLabel
        control={<Switch checked={helpWithAI} onChange={handleHelpWithAIChange} />}
        label={getMessage("label_create_questions_with_ai")}
      /> 
    </Box>

    <Box ml="20px" mr="20px">
      <Box sx={{p: "10px", borderRadius: "4px",}}>

          <Formik
            initialValues={initialQuestionValues}
            onSubmit={onSubmit}
            validationSchema={Yup.lazy(values => addQuestionsSchema(splitWorkshops, helpWithAI, values.questionType))}
          >   

          {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <FormResetWatcher splitWorkshops={splitWorkshops} initialQuestionValues={initialQuestionValues} />
            {splitWorkshops && (
              
              <FormControl fullWidth>
                  <InputLabel id="selectedWorkshop">{getMessage("label_choose_category")}</InputLabel>
                  <Select
                  labelId="selectedWorkshop"
                  id="selectedWorkshop"
                  name="workshop"
                  value={values.workshop}
                  label={getMessage("label_choose_category")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.workshop && errors.workshop ? true : false}
                  sx={{mb:"15px"}}
                >
                  {workshops.length > 0 && 
                    workshops.map((workshop, index) => (
                      <MenuItem key={index} value={workshop}>
                        {workshop}
                      </MenuItem>
                    ))
                  }
                </Select>
                </FormControl>
              )}

              {!helpWithAI ? (
              <>
                {/* choose the question type */}
                <QuestionTypeSelector
                  name="questionType"
                  label="label_choose_question_type"
                  getMessage={getMessage}
                  sx={{ mb: "15px" }}
                  {...(assessmentType === AssessmentType.BEHAVIORAL_CHANGES && { optionsTypes: [QuestionType.TEXT, QuestionType.SINGLE_TEXT, QuestionType.RADIO_UNORDERED, QuestionType.RADIO_ORDERED, QuestionType.CHECKBOX]})}
                />

                {/* type single text */}
                {values.questionType === QuestionType.SINGLE_TEXT && (
                  <Box margin="normal">
                    <Box border={1} borderColor="grey.400" p={2} mb={2} borderRadius={1}>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={setEditorState}
                        toolbar={toolbarConfig}
                      />
                    </Box>
                  </Box>
                )}

                {/* other types */}
                {values.questionType !== QuestionType.SINGLE_TEXT && (
                  <>

                  {/* question */}
                  <TextFieldWrapper
                    name="question"
                    label="label_enter_question"
                    getMessage={getMessage}
                    fullWidth
                    sx={{ mb: '15px' }}
                  />

                  {/* question short name */}
                  <TextFieldWrapper
                    name="shortName"
                    label="label_enter_question_short_name"
                    getMessage={getMessage}
                    fullWidth
                    sx={{ mb: '15px' }}
                  />

                {/* adoption type */}
                {assessmentType === AssessmentType.BEHAVIORAL_CHANGES && (
                    <AdoptionTypeSelector
                      name="adoptionType"
                      label="label_adoption_type"
                      getMessage={getMessage}
                      sx={{ mb: "15px" }}
                    />
                )}

                {/* choose the learning type */}
                <LearningTypeSelector
                    name="learningType"
                    label="label_learning_type"
                    getMessage={getMessage}
                    {...(assessmentType === AssessmentType.STUDENT_LEARNING_OUTCOMES && { learningTypes: [LearningType.KNOWLEDGE, LearningType.SKILL, LearningType.ATTITUDE]})}
                    sx={{ mb: "15px" }}
                  />

                {/* choose the framework */}
                <FrameworkSelector
                    name="framework"
                    label="label_choose_framework"
                    getMessage={getMessage}
                    competenceAreas={assessmentType === AssessmentType.STUDENT_LEARNING_OUTCOMES ? studentCompetenceAreas : traineeCompetenceAreas }
                    sx={{ mb: "15px" }}
                  />

                {/* choose the competencies */}
                <CompetenciesSelector
                  getMessage={getMessage}
                  competenceAreas={assessmentType === AssessmentType.STUDENT_LEARNING_OUTCOMES ? studentCompetenceAreas : traineeCompetenceAreas }
                  getCompetencies={getCompetencies}
                  getActivities={getActivities}
                  automaticEncoding={automaticEncoding}
                  setAutomaticEncoding={setAutomaticEncoding}
                  selectedCompetencies={selectedCompetencies}
                  setSelectedCompetencies={setSelectedCompetencies}
                  handleAutomaticEncodingChange={handleAutomaticEncodingChange}
                  handleCompetencyChange={handleCompetencyChange}
                  handleDeleteCompetency={handleDeleteCompetency}
                  helpWithAI={helpWithAI}
                  updateCompetenciesForQuestion={updateCompetenciesForQuestion}
                />

                </>
              )}

              {values.questionType !== QuestionType.TEXT && values.questionType !== QuestionType.SINGLE_TEXT && (
                <>
                  <ChooseOptions getMessage={getMessage} buttonStyle={buttonStyle} />
                
                  {/* give the explanation */}
                  <TextFieldWrapper
                    name="explanation"
                    label="label_give_explanation"
                    getMessage={getMessage}
                    fullWidth
                    multiline
                    rows={5}
                    sx={{ mb: '15px' }}
                  />
              </>
              )}
              </>

            ) : (

            helpWithAI && (
              <>
              
              {/* textfield to enter ai instruction */}
              <TextFieldWrapper
                name="explanation"
                label="instruction_ai_prompt"
                getMessage={getMessage}
                fullWidth
                multiline
                rows={7}
                sx={{ mb: '15px' }}
              />

              {/* learning type */}
              <LearningTypeSelector
                name="learningType"
                label="label_learning_type"
                getMessage={getMessage}
                {...(assessmentType === AssessmentType.STUDENT_LEARNING_OUTCOMES && { learningTypes: [LearningType.KNOWLEDGE, LearningType.SKILL, LearningType.ATTITUDE]})}
                sx={{ mb: "15px" }}
              />

              {/* choose framework */}
              <FrameworkSelector
                name="framework"
                label="label_choose_framework"
                getMessage={getMessage}
                competenceAreas={assessmentType === AssessmentType.STUDENT_LEARNING_OUTCOMES ? studentCompetenceAreas: traineeCompetenceAreas }
                sx={{ mb: "15px" }}
              />

              {/* Wether to automatically encode competencies or not - mandatory if with AI */}
              <FormControlLabel
                control={<Switch 
                checked={automaticEncoding} 
                onChange={(event) => handleAutomaticEncodingChange(values.question, values.shortName, values.framework, event)}
                disabled={helpWithAI} />}
                label={getMessage("label_automatic_encoding_competencies")}
                sx={{mb:"15px"}}
              /> 

              {/* Choose number of question */}
              <ChooseNumberQuestions getMessage={getMessage} />

            </>
          ))}

          {/* Button to submit the form */}
          <Box sx={{mt:"30px"}}>
            <Button
              type="submit"
              variant="contained"
              sx={ buttonStyle }
              disabled={isLoading} // Disable the button when loading
            >
              {isLoading ? <CircularProgress size={24} /> : <Typography variant="h5">{getMessage("label_add")}</Typography>}

            </Button>
          </Box>

        </Form>
        )}
      </Formik>
    </Box>
  </Box>
  </>
)};

export default AddLearningQuestions;
